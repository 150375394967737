
// @ts-nocheck
import locale_en_45GB_46ts_002f88bd from "#nuxt-i18n/002f88bd";
import locale_en_45GB_46ts_56cd6036 from "#nuxt-i18n/56cd6036";
import locale_de_45DE_46ts_39de5f27 from "#nuxt-i18n/39de5f27";
import locale_de_45DE_46ts_4707abc3 from "#nuxt-i18n/4707abc3";

export const localeCodes =  [
  "en-GB",
  "de-DE"
]

export const localeLoaders = {
  "en-GB": [
    {
      key: "locale_en_45GB_46ts_002f88bd",
      load: () => Promise.resolve(locale_en_45GB_46ts_002f88bd),
      cache: true
    },
    {
      key: "locale_en_45GB_46ts_56cd6036",
      load: () => Promise.resolve(locale_en_45GB_46ts_56cd6036),
      cache: false
    }
  ],
  "de-DE": [
    {
      key: "locale_de_45DE_46ts_39de5f27",
      load: () => Promise.resolve(locale_de_45DE_46ts_39de5f27),
      cache: true
    },
    {
      key: "locale_de_45DE_46ts_4707abc3",
      load: () => Promise.resolve(locale_de_45DE_46ts_4707abc3),
      cache: false
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/8ff156a9" /* webpackChunkName: "config_config_46ts_8ff156a9" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "./localeDetector.ts",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en-GB",
      language: "en-GB",
      files: [
        "/app/shared-layers/altcha/i18n/locales/en-GB.ts",
        "/app/shop/i18n/src/langs/en-GB.ts"
      ]
    },
    {
      code: "de-DE",
      language: "de-DE",
      files: [
        "/app/shared-layers/altcha/i18n/locales/de-DE.ts",
        "/app/shop/i18n/src/langs/de-DE.ts"
      ]
    }
  ],
  defaultLocale: "de-DE",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "./src/langs/",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en-GB",
    language: "en-GB",
    files: [
      {
        path: "/app/shared-layers/altcha/i18n/locales/en-GB.ts",
        cache: undefined
      },
      {
        path: "/app/shop/i18n/src/langs/en-GB.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "de-DE",
    language: "de-DE",
    files: [
      {
        path: "/app/shared-layers/altcha/i18n/locales/de-DE.ts",
        cache: undefined
      },
      {
        path: "/app/shop/i18n/src/langs/de-DE.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/