import revive_payload_client_IEa9xz4x8UffTSREaPve0iAlp8za5p3eSb0Hj_5fgiM from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NKqI1nRBFoOnTMuWHqO_9B9907AO_7YDKzmjhNNXt_o from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FbSlpkERZWsThY0VgzYrvz4Z6Msl12LuyAk_DVnGMJE from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_CKOcOhPzZKXDn8QJ81HZkGfD36QC_r3lfpXNN9mYkCE from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Vga_BcAsUQsW2BaGJv4AzFuzzniBkwnZ_v70gafhZPY from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_FxkEEL6OA55glZ5VEScyWRUSBnxvK_xo1jaOpRnzzWY from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/shop/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Ts8sqQyoG4TXgKveUsTQkwE3_fs2XbfxjljHe6d0UAY from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_xILp7wd_PEb_spyOt7iXcXmgN1eyRJN5moPenhGbyys from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_DBUgaBYbso0_iYK4qGElbE0rNB_aoMhgoOxn5EhaETA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_bCy0fZgm5GoY6zyX3LhE4sqB4TBwdMHWI_Dq3gcY2Ek from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_4GDqlym1MZNly7Ei9mRm5kmH_m3kLz6XSQoO1dfDSqg from "/app/node_modules/.pnpm/@shopware+nuxt-module@1.3.1_magicast@0.3.5_typescript@5.8.2/node_modules/@shopware/nuxt-module/plugin.ts";
import unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0 from "/app/shop/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import ssr_plugin_rr4BwepsbkY_Ad9_aV92pICcmmxGlRv8FuzrLRzBuL0 from "/app/node_modules/.pnpm/@vueuse+nuxt@13.0.0_magicast@0.3.5_nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13._3a1a360f30c1fd82e01b56594421cc9e/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import vue_gtm__htD_alaKWdiIqvLAXs2GV5RMJ55BayFfLOIZ60wnZo from "/app/shop/app/plugins/vue-gtm.ts";
import ssg_detect_orebT05LTLRb98cIMxMjOM_xYOKSrlnRXdP_cZHNBQo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_IEa9xz4x8UffTSREaPve0iAlp8za5p3eSb0Hj_5fgiM,
  unhead_NKqI1nRBFoOnTMuWHqO_9B9907AO_7YDKzmjhNNXt_o,
  router_FbSlpkERZWsThY0VgzYrvz4Z6Msl12LuyAk_DVnGMJE,
  payload_client_CKOcOhPzZKXDn8QJ81HZkGfD36QC_r3lfpXNN9mYkCE,
  navigation_repaint_client_Vga_BcAsUQsW2BaGJv4AzFuzzniBkwnZ_v70gafhZPY,
  chunk_reload_client_FxkEEL6OA55glZ5VEScyWRUSBnxvK_xo1jaOpRnzzWY,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_Ts8sqQyoG4TXgKveUsTQkwE3_fs2XbfxjljHe6d0UAY,
  switch_locale_path_ssr_xILp7wd_PEb_spyOt7iXcXmgN1eyRJN5moPenhGbyys,
  route_locale_detect_DBUgaBYbso0_iYK4qGElbE0rNB_aoMhgoOxn5EhaETA,
  i18n_bCy0fZgm5GoY6zyX3LhE4sqB4TBwdMHWI_Dq3gcY2Ek,
  plugin_4GDqlym1MZNly7Ei9mRm5kmH_m3kLz6XSQoO1dfDSqg,
  unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0,
  ssr_plugin_rr4BwepsbkY_Ad9_aV92pICcmmxGlRv8FuzrLRzBuL0,
  vue_gtm__htD_alaKWdiIqvLAXs2GV5RMJ55BayFfLOIZ60wnZo,
  ssg_detect_orebT05LTLRb98cIMxMjOM_xYOKSrlnRXdP_cZHNBQo
]