import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_9c5b55b1fcbdfa5fc83d40b3cba8223d/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  linkActiveClass: "link-active",
  linkExactActiveClass: "link-exact-active text-primary",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}