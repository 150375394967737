import type { Schemas } from "#shopware";

// Inspired by `@shopware-pwa\composables-next\src\useLocalWishlist.ts`

const MAX_ITEMS_IN_LIST = 5;

const _storageKey = "sp-compare-list-items";

const _compareListItems: Ref<string[]> = ref([]);

export function useLocalCompareList() {
  function updateStorage() {
    localStorage.setItem(_storageKey, JSON.stringify(_compareListItems.value));
  }

  function getFromStorage() {
    if (typeof window == "undefined" || !localStorage) return undefined;
    return JSON.parse(localStorage.getItem(_storageKey) ?? "[]") as string[];
  }

  async function removeFromCompareList(id: string) {
    _compareListItems.value = _compareListItems.value?.filter((x) => x != id);
    updateStorage();
  }

  async function addToCompareList(id: string) {
    if (_compareListItems.value.includes(id)) return false;
    if (_compareListItems.value.length >= MAX_ITEMS_IN_LIST) return false;
    _compareListItems.value = [..._compareListItems.value, id];
    updateStorage();
    return true;
  }

  async function clearCompareList() {
    _compareListItems.value = [];
    updateStorage();
  }

  function getCompareListProducts() {
    const currentList = getFromStorage();
    if (Array.isArray(currentList) && currentList.length) {
      _compareListItems.value = currentList;
    }
  }

  const items = computed(() => _compareListItems.value);
  const count = computed(() => items.value.length);
  const containsProducts = computed(() => count.value > 0);

  return {
    getCompareListProducts,
    addToCompareList,
    removeFromCompareList,
    clearCompareList,
    items,
    count,
    containsProducts,
  };
}

export function useProductCompareList(product: Ref<Schemas["Product"]>) {
  const {
    addToCompareList: addItem,
    removeFromCompareList: removeItem,
    items,
    count,
    containsProducts,
  } = useLocalCompareList();
  const { pushSuccess, pushError } = useNotifications();
  const { t } = useI18n();

  function removeFromCompareList() {
    removeItem(product.value.id);
  }

  function addToCompareList() {
    return addItem(product.value.id);
  }

  const isInCompareList = computed(() =>
    items.value?.includes(product.value.id),
  );

  async function toggleCompareListProduct() {
    if (product.value.customFields?.variantType === "set") {
      return pushError(t(`product.messages.compareListSetCannotBeCompared`));
    }
    if (!isInCompareList.value) {
      const success = await addToCompareList();
      if (!success) {
        return pushError(
          t(`product.messages.compareListNumItemsExceeded`, {
            n: MAX_ITEMS_IN_LIST,
          }),
        );
      } else {
        return pushSuccess(
          t(`product.messages.addedToCompareList`, {
            p: product?.value.translated?.name,
          }),
        );
      }
    }
    removeFromCompareList();
  }

  return {
    addToCompareList,
    removeFromCompareList,
    isInCompareList,
    toggleCompareListProduct,
    items,
    count,
    containsProducts,
  };
}
