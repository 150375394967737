import type { Schemas } from "#shopware";
import { useUser as swUseUser } from "@shopware/composables";

export const useUser = () => {
  const useUserData = swUseUser();

  // here you can experiment with overriding the default composable

  return {
    ...useUserData,
  } as typeof useUserData & {
    refreshUser(params: Schemas["Criteria"]): Promise<void>;
  };
};
