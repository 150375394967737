<setup setupt lang="ts"></setup>

<template>
  <NuxtLink
    to="/spare-parts"
    class="my-5 font-bold text-20px c-scheppach-primary-500"
    target="_blank"
  >
    <p class="mb-2 sm:mb-1 mt-5 text-6 sm:text-4.5 md:text-5 text-center">
      {{ $t("listing.toSparePartSearch") }}
    </p>
    <img
      class="w-300px md:w-350px mx-a xl:w-300px rd b b-scheppach-neutral-500"
      src="../../../public/images/spare-parts-image.jpg"
      alt="Spare-Parts Image"
    />
  </NuxtLink>
</template>
