import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  if (window) {
    if (!window.dataLayer) window.dataLayer = [];

    window.dataLayer.push([
      "consent",
      "default",
      {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 2000, // milliseconds to wait for update
      },
    ]);
  }

  nuxtApp.vueApp.use(
    createGtm({
      ...nuxtApp.$config.public.gtm,
      vueRouter: useRouter(),
    }),
  );
});
