
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46all_9350kqhWk8tAqXSvnDEzLl9035NyKqtJwLZWt6yccYdtsMeta } from "/app/shop/app/pages/[...all].vue?macro=true";
import { default as address4Aw13R5F_0byKVp4rktiGtqJ1Luh6WS1rKqtPGxc7G0Meta } from "/app/shop/app/pages/account/address.vue?macro=true";
import { default as indexr4pvIz2JZj_9hEIhHXkSoD45eo6yn5oynIrqggBpEU8Meta } from "/app/shop/app/pages/account/index.vue?macro=true";
import { default as loginkja8NPV_GYV6rE3KLss1iAE55J_45lYwhYI6O7_45Tx0JZQMeta } from "/app/shop/app/pages/account/login.vue?macro=true";
import { default as ordersZY6T8_45Nf73MVZKqKCkAPcCGe1k2_ePwmOkoAPpJB_45IMeta } from "/app/shop/app/pages/account/order.vue?macro=true";
import { default as paymentaMq37zCt7Sf4QOmoUY90jY0FutyKZIVVShkziUUkDyoMeta } from "/app/shop/app/pages/account/payment.vue?macro=true";
import { default as profileJ8M7ohqoOEEe4k7hJT3n2cW99BS7UuOUOg7Pe9F1RHYMeta } from "/app/shop/app/pages/account/profile.vue?macro=true";
import { default as password9_nxwxE0k_ofywThz34TQsSuDfNWaHyL7xZsNX07FRwMeta } from "/app/shop/app/pages/account/recover/password.vue?macro=true";
import { default as reset_45password3h4NYcSbC9o7_DU_459xOlMPMD6QAeyULJqyQFq4PcMkUMeta } from "/app/shop/app/pages/account/reset-password.vue?macro=true";
import { default as wishlistB4iQtTo32NS_W1SlU8_I2C5iSNKkoSmdVW6oqsaUoj4Meta } from "/app/shop/app/pages/account/wishlist.vue?macro=true";
import { default as cartbo0U727aR6me6P0uYCfG4kVYwT2dmPhhV0echL7HxCQMeta } from "/app/shop/app/pages/checkout/cart.vue?macro=true";
import { default as indexbdrDegDwvZf8a1YurIzFl9bAUYuT_45JPoTbZrdBDqMXUMeta } from "/app/shop/app/pages/checkout/index.vue?macro=true";
import { default as re_45payYLM3l5adp6jCjF_459XCedGHv18RRkQuxeRHepWHQZXTQMeta } from "/app/shop/app/pages/checkout/re-pay.vue?macro=true";
import { default as indexLDIJHQ6cHStI5TesKb9DvHCPwyTfWRAy_7KBgfmBvSsMeta } from "/app/shop/app/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paiduPHSH7V9fjnrDX1nhzP2Prug3N_45OoWzfB8LPNNxkU8sMeta } from "/app/shop/app/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaid8SS3qnkDjuhTtf5D5rdNWDUN7hUXcWpNaQAbOmUHhDYMeta } from "/app/shop/app/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as _91productIds_930k4RkIaG2eBzEMQRkDLTjl9kunD1UBv8DakHVmA21M4Meta } from "/app/shop/app/pages/compare/[productIds].vue?macro=true";
import { default as indexSpotfy4yDAK_45txrKfxzGvkv2g6LlTYWMu9gSSL6YnhUMeta } from "/app/shop/app/pages/compare/index.vue?macro=true";
import { default as claim_45formnBDNKdq_l3xCZvUsUZr_45Jaizf5SxbbAZlvEf_8f4NVAMeta } from "/app/shop/app/pages/contact/claim-form.vue?macro=true";
import { default as general_45contact_45inquiry6KB19SeE3H2qPz12rvCqmbBffkcoriyN5NF3WONtD2IMeta } from "/app/shop/app/pages/contact/general-contact-inquiry.vue?macro=true";
import { default as index7HOyk1D9yQ66W_D5ZBjc_T9ND8XckgjPADeEThiotXkMeta } from "/app/shop/app/pages/contact/index.vue?macro=true";
import { default as order_45questionZCG77t0L18uv79HvVr2_1Zx6BIna_aK3UJ4lkV_8SeQMeta } from "/app/shop/app/pages/contact/order-question.vue?macro=true";
import { default as product_45question3XeXoldeWE_XjQ2ztTngVfEZNEO7qL0LZ_45RlINH_IgkMeta } from "/app/shop/app/pages/contact/product-question.vue?macro=true";
import { default as gift_45cardwuv60dMPC5LzaCocORdE2f4aJYGD16UN2cpjDSPedbIMeta } from "/app/shop/app/pages/gift-card.vue?macro=true";
import { default as promotion_45editor2ngALH2xVh_45t2xf2NA0P6tiwP1nrZdf4pJ4QgQPGhvEMeta } from "/app/shop/app/pages/member/promotion-editor.vue?macro=true";
import { default as newsletter_45subscribeEwOIuvKv1LoWN8h_cg_v4r_Zry5rgBbnAWrNfWHFji4Meta } from "/app/shop/app/pages/newsletter-subscribe.vue?macro=true";
import { default as registerxMOt0uDypR0XHIiiuWOzDR3c_GYi0YTcZwBTEWqgVLcMeta } from "/app/shop/app/pages/register.vue?macro=true";
import { default as searchudF2eJTNiVhZTo5mmAhXP3RFQyI6a7X21FSgFG4F310Meta } from "/app/shop/app/pages/search.vue?macro=true";
import { default as index5k69gmteaiWHN3FMMUJ3WXa9SkvrdZiS3kKhyo8_pBoMeta } from "/app/shop/app/pages/spare-parts/index.vue?macro=true";
import { default as testpage_45ui_45elementsqqWi8SSver3mF9a_A4MVxqOCwPSRwyhdund29UsJPcIMeta } from "/app/shop/app/pages/testpage-ui-elements.vue?macro=true";
import { default as wishlistbpE8IMnuZnjGHxcjOWRMS_sUvBUA9AT37lvkUnSXs_QMeta } from "/app/shop/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "all___en-GB",
    path: "/en-GB/:all(.*)*",
    component: () => import("/app/shop/app/pages/[...all].vue")
  },
  {
    name: "all___de-DE",
    path: "/:all(.*)*",
    component: () => import("/app/shop/app/pages/[...all].vue")
  },
  {
    name: "account-address___en-GB",
    path: "/en-GB/account/address",
    meta: address4Aw13R5F_0byKVp4rktiGtqJ1Luh6WS1rKqtPGxc7G0Meta || {},
    component: () => import("/app/shop/app/pages/account/address.vue")
  },
  {
    name: "account-address___de-DE",
    path: "/account/address",
    meta: address4Aw13R5F_0byKVp4rktiGtqJ1Luh6WS1rKqtPGxc7G0Meta || {},
    component: () => import("/app/shop/app/pages/account/address.vue")
  },
  {
    name: "account___en-GB",
    path: "/en-GB/account",
    meta: indexr4pvIz2JZj_9hEIhHXkSoD45eo6yn5oynIrqggBpEU8Meta || {},
    component: () => import("/app/shop/app/pages/account/index.vue")
  },
  {
    name: "account___de-DE",
    path: "/account",
    meta: indexr4pvIz2JZj_9hEIhHXkSoD45eo6yn5oynIrqggBpEU8Meta || {},
    component: () => import("/app/shop/app/pages/account/index.vue")
  },
  {
    name: "account-login___en-GB",
    path: "/en-GB/account/login",
    component: () => import("/app/shop/app/pages/account/login.vue")
  },
  {
    name: "account-login___de-DE",
    path: "/account/login",
    component: () => import("/app/shop/app/pages/account/login.vue")
  },
  {
    name: "account-order___en-GB",
    path: "/en-GB/account/order",
    meta: ordersZY6T8_45Nf73MVZKqKCkAPcCGe1k2_ePwmOkoAPpJB_45IMeta || {},
    component: () => import("/app/shop/app/pages/account/order.vue")
  },
  {
    name: "account-order___de-DE",
    path: "/account/order",
    meta: ordersZY6T8_45Nf73MVZKqKCkAPcCGe1k2_ePwmOkoAPpJB_45IMeta || {},
    component: () => import("/app/shop/app/pages/account/order.vue")
  },
  {
    name: "account-payment___en-GB",
    path: "/en-GB/account/payment",
    meta: paymentaMq37zCt7Sf4QOmoUY90jY0FutyKZIVVShkziUUkDyoMeta || {},
    component: () => import("/app/shop/app/pages/account/payment.vue")
  },
  {
    name: "account-payment___de-DE",
    path: "/account/payment",
    meta: paymentaMq37zCt7Sf4QOmoUY90jY0FutyKZIVVShkziUUkDyoMeta || {},
    component: () => import("/app/shop/app/pages/account/payment.vue")
  },
  {
    name: "account-profile___en-GB",
    path: "/en-GB/account/profile",
    meta: profileJ8M7ohqoOEEe4k7hJT3n2cW99BS7UuOUOg7Pe9F1RHYMeta || {},
    component: () => import("/app/shop/app/pages/account/profile.vue")
  },
  {
    name: "account-profile___de-DE",
    path: "/account/profile",
    meta: profileJ8M7ohqoOEEe4k7hJT3n2cW99BS7UuOUOg7Pe9F1RHYMeta || {},
    component: () => import("/app/shop/app/pages/account/profile.vue")
  },
  {
    name: "account-recover-password___en-GB",
    path: "/en-GB/account/recover/password",
    component: () => import("/app/shop/app/pages/account/recover/password.vue")
  },
  {
    name: "account-recover-password___de-DE",
    path: "/account/recover/password",
    component: () => import("/app/shop/app/pages/account/recover/password.vue")
  },
  {
    name: "account-reset-password___en-GB",
    path: "/en-GB/account/reset-password",
    component: () => import("/app/shop/app/pages/account/reset-password.vue")
  },
  {
    name: "account-reset-password___de-DE",
    path: "/account/reset-password",
    component: () => import("/app/shop/app/pages/account/reset-password.vue")
  },
  {
    name: "account-wishlist___en-GB",
    path: "/en-GB/account/wishlist",
    meta: wishlistB4iQtTo32NS_W1SlU8_I2C5iSNKkoSmdVW6oqsaUoj4Meta || {},
    component: () => import("/app/shop/app/pages/account/wishlist.vue")
  },
  {
    name: "account-wishlist___de-DE",
    path: "/account/wishlist",
    meta: wishlistB4iQtTo32NS_W1SlU8_I2C5iSNKkoSmdVW6oqsaUoj4Meta || {},
    component: () => import("/app/shop/app/pages/account/wishlist.vue")
  },
  {
    name: "checkout-cart___en-GB",
    path: "/en-GB/checkout/cart",
    meta: cartbo0U727aR6me6P0uYCfG4kVYwT2dmPhhV0echL7HxCQMeta || {},
    component: () => import("/app/shop/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___de-DE",
    path: "/checkout/cart",
    meta: cartbo0U727aR6me6P0uYCfG4kVYwT2dmPhhV0echL7HxCQMeta || {},
    component: () => import("/app/shop/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout___en-GB",
    path: "/en-GB/checkout",
    meta: indexbdrDegDwvZf8a1YurIzFl9bAUYuT_45JPoTbZrdBDqMXUMeta || {},
    component: () => import("/app/shop/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___de-DE",
    path: "/checkout",
    meta: indexbdrDegDwvZf8a1YurIzFl9bAUYuT_45JPoTbZrdBDqMXUMeta || {},
    component: () => import("/app/shop/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-re-pay___en-GB",
    path: "/en-GB/checkout/re-pay",
    component: () => import("/app/shop/app/pages/checkout/re-pay.vue")
  },
  {
    name: "checkout-re-pay___de-DE",
    path: "/checkout/re-pay",
    component: () => import("/app/shop/app/pages/checkout/re-pay.vue")
  },
  {
    name: "checkout-success-id___en-GB",
    path: "/en-GB/checkout/success/:id()",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___de-DE",
    path: "/checkout/success/:id()",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id-paid___en-GB",
    path: "/en-GB/checkout/success/:id()/paid",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___de-DE",
    path: "/checkout/success/:id()/paid",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-unpaid___en-GB",
    path: "/en-GB/checkout/success/:id()/unpaid",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "checkout-success-id-unpaid___de-DE",
    path: "/checkout/success/:id()/unpaid",
    component: () => import("/app/shop/app/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "compare-productIds___en-GB",
    path: "/en-GB/compare/:productIds()",
    component: () => import("/app/shop/app/pages/compare/[productIds].vue")
  },
  {
    name: "compare-productIds___de-DE",
    path: "/compare/:productIds()",
    component: () => import("/app/shop/app/pages/compare/[productIds].vue")
  },
  {
    name: "compare___en-GB",
    path: "/en-GB/compare",
    component: () => import("/app/shop/app/pages/compare/index.vue")
  },
  {
    name: "compare___de-DE",
    path: "/compare",
    component: () => import("/app/shop/app/pages/compare/index.vue")
  },
  {
    name: "contact-claim-form___en-GB",
    path: "/en-GB/contact/claim-form",
    component: () => import("/app/shop/app/pages/contact/claim-form.vue")
  },
  {
    name: "contact-claim-form___de-DE",
    path: "/contact/claim-form",
    component: () => import("/app/shop/app/pages/contact/claim-form.vue")
  },
  {
    name: "contact-general-contact-inquiry___en-GB",
    path: "/en-GB/contact/general-contact-inquiry",
    component: () => import("/app/shop/app/pages/contact/general-contact-inquiry.vue")
  },
  {
    name: "contact-general-contact-inquiry___de-DE",
    path: "/contact/general-contact-inquiry",
    component: () => import("/app/shop/app/pages/contact/general-contact-inquiry.vue")
  },
  {
    name: "contact___en-GB",
    path: "/en-GB/contact",
    component: () => import("/app/shop/app/pages/contact/index.vue")
  },
  {
    name: "contact___de-DE",
    path: "/contact",
    component: () => import("/app/shop/app/pages/contact/index.vue")
  },
  {
    name: "contact-order-question___en-GB",
    path: "/en-GB/contact/order-question",
    component: () => import("/app/shop/app/pages/contact/order-question.vue")
  },
  {
    name: "contact-order-question___de-DE",
    path: "/contact/order-question",
    component: () => import("/app/shop/app/pages/contact/order-question.vue")
  },
  {
    name: "contact-product-question___en-GB",
    path: "/en-GB/contact/product-question",
    component: () => import("/app/shop/app/pages/contact/product-question.vue")
  },
  {
    name: "contact-product-question___de-DE",
    path: "/contact/product-question",
    component: () => import("/app/shop/app/pages/contact/product-question.vue")
  },
  {
    name: "gift-card___en-GB",
    path: "/en-GB/gift-card",
    component: () => import("/app/shop/app/pages/gift-card.vue")
  },
  {
    name: "gift-card___de-DE",
    path: "/gift-card",
    component: () => import("/app/shop/app/pages/gift-card.vue")
  },
  {
    name: "member-promotion-editor___en-GB",
    path: "/en-GB/member/promotion-editor",
    component: () => import("/app/shop/app/pages/member/promotion-editor.vue")
  },
  {
    name: "member-promotion-editor___de-DE",
    path: "/member/promotion-editor",
    component: () => import("/app/shop/app/pages/member/promotion-editor.vue")
  },
  {
    name: "newsletter-subscribe___en-GB",
    path: "/en-GB/newsletter-subscribe",
    component: () => import("/app/shop/app/pages/newsletter-subscribe.vue")
  },
  {
    name: "newsletter-subscribe___de-DE",
    path: "/newsletter-subscribe",
    component: () => import("/app/shop/app/pages/newsletter-subscribe.vue")
  },
  {
    name: "register___en-GB",
    path: "/en-GB/register",
    component: () => import("/app/shop/app/pages/register.vue")
  },
  {
    name: "register___de-DE",
    path: "/register",
    component: () => import("/app/shop/app/pages/register.vue")
  },
  {
    name: "search___en-GB",
    path: "/en-GB/search",
    component: () => import("/app/shop/app/pages/search.vue")
  },
  {
    name: "search___de-DE",
    path: "/search",
    component: () => import("/app/shop/app/pages/search.vue")
  },
  {
    name: "spare-parts___en-GB",
    path: "/en-GB/spare-parts",
    component: () => import("/app/shop/app/pages/spare-parts/index.vue")
  },
  {
    name: "spare-parts___de-DE",
    path: "/spare-parts",
    component: () => import("/app/shop/app/pages/spare-parts/index.vue")
  },
  {
    name: "testpage-ui-elements___en-GB",
    path: "/en-GB/testpage-ui-elements",
    component: () => import("/app/shop/app/pages/testpage-ui-elements.vue")
  },
  {
    name: "testpage-ui-elements___de-DE",
    path: "/testpage-ui-elements",
    component: () => import("/app/shop/app/pages/testpage-ui-elements.vue")
  },
  {
    name: "wishlist___en-GB",
    path: "/en-GB/wishlist",
    component: () => import("/app/shop/app/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-DE",
    path: "/wishlist",
    component: () => import("/app/shop/app/pages/wishlist.vue")
  }
]