import type { Schemas } from "#shopware";
import { useCartFunction as swUseCart } from "@shopware/composables";

type ExtendedUseCartReturn = UseCartReturn & {
  isShipmentSpedi: () => boolean;
};

const _useCart = (): ExtendedUseCartReturn => {
  const useCartData: UseCartReturn = swUseCart();

  /**
   * Example on how to override the default `refreshCart` method
   *
   * @param {Schemas["Cart"]} newCart
   * @returns
   */
  async function refreshCart(
    newCart?: Schemas["Cart"],
  ): Promise<Schemas["Cart"]> {
    return useCartData.refreshCart(newCart);
  }

  // async function submitPromotionCode(
  //   promotionCode: string,
  // ): Promise<Schemas["Cart"]> {
  //   const data = useCartData.addPromotionCode(promotionCode);
  //   return data;
  // }

  function isShipmentSpedi(): boolean {
    return useCartData.cartItems.value.some(
      (item) => item?.payload?.customFields?.shipmentType == "spedi",
    );
  }

  return {
    ...useCartData,
    refreshCart,
    isShipmentSpedi,
    // addPromotionCode: submitPromotionCode,
  };
};

export const useCart = createSharedComposable(_useCart);
